<template>
  <div class="mobile-nav">

    <!-- logo -->
    <img src="../../assets/logo/logo.png" alt="logo image">
    
    <div class="bell-nav">
      <fa-icon :icon="['fas', 'bell']" class="bell-icon" />

      <div 
      class="icon-wrap"
      :class="showNavPanel ? 'icon-wrap-active': 'bg-[#104438]'">
        <span 
        class="material-symbols-rounded"
        :class="showNavPanel ? 'cancel-icon' : 'nav-icon'"
        @click="toggleShowNav">
        
          {{ showNavPanel ? 'close' : 'grid_view' }}
        </span>      

      </div>
    </div>
  </div>

  <!-- mobile navigation modal -->
  <div 
  class="modal-overlay" 
  @click="checkClick" 
  ref="mobileNav"
  v-show="showNavPanel">

    <div class="nav-panel" v-show="showNavPanel">
      <nav-buttons
      @click="closeModal"
        v-for="(navBtn,index) in navBtnArray"
        :key="index"
        :title= navBtn.navTitle
        :iconImg = navBtn.navIcon
        :aIconImg =  navBtn.activeNavIcon
        :routeLink = navBtn.navRoute
        
      />
    </div>

  </div>
</template>

<script>
import NavButtons from './NavButtons.vue';

export default {
  name: 'MobileNav',

  components: { NavButtons },

  data() {
    return {
      showNavPanel: false,

      navBtnArray: [
        {
          navIcon: 'in_dashboard',
          activeNavIcon: 'a_dashboard',
          navTitle: 'Dashboard',
          navRoute: 'dashboard'
        },
        
        {
          navIcon: 'in_document',
          activeNavIcon: 'a_document',
          navTitle: 'Documents',
          navRoute: 'folder'
        },
        {
          navIcon: 'in_briefcase',
          activeNavIcon: 'a_briefcase',
          navTitle: 'Projects',
          navRoute: 'proj'
        },
        {
          navIcon: 'in_billing',
          activeNavIcon: 'a_billing',
          navTitle: 'Billings',
          navRoute: 'billing'
        },
        {
          navIcon: 'in_marketing',
          activeNavIcon: 'a_marketing',
          navTitle: 'Marketing',
          navRoute: 'marketing'
        },
        {
          navIcon: 'in_moneys',
          activeNavIcon: 'a_moneys',
          navTitle: 'Reports',
          navRoute: 'reports'
        },
        {
          navIcon: 'in_setting',
          activeNavIcon: 'in_setting',
          navTitle: 'Settings',
          navRoute: 'setting'
        },
        
      ]
    }
  },

  methods: {
    closeModal() {
      setTimeout(() => {
        this.toggleShowNav();
      }, 1500);
    },

    toggleShowNav() {
      this.showNavPanel = !this.showNavPanel;
    },

    checkClick(e) {
      if(e.target === this.$refs.mobileNav) {
        this.toggleShowNav();
      }
    },
  }

}
</script>

<style scoped lang="scss">
  .modal-overlay {
    @apply flex justify-center items-start px-7 pt-[150px] pb-6;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 3; 
  }

  .mobile-nav {
    @apply flex flex-row gap-3 px-5 pt-4 pb-3 bg-white justify-between items-center;

    img {
      @apply object-cover max-w-[110px] max-h-[100px];
    }
    
    .bell-nav {
      @apply flex flex-row gap-3 justify-end items-center;

      .bell-icon {
        @apply w-6 h-5 text-[#E3E3E3];

        @screen md {
          @apply w-8 h-5
        }
      }

      .icon-wrap {
        @apply w-10 h-10 rounded-full p-1 flex items-center justify-center;

        .nav-icon {
          @apply text-white text-[20px] font-medium 
        }

        .material-symbols-rounded {
          font-variation-settings:
          'FILL' 1,
        }

        .cancel-icon {
          @apply text-black font-medium;
        }

      }

      .icon-wrap-active {
        background-color: white;
        z-index: 4;
      }

    }
  }

  .nav-panel {
    @apply py-4 px-3 relative flex flex-row flex-wrap gap-y-6 gap-x-4 items-center justify-center text-start text-[#141515] bg-white rounded-2xl ;
  }

</style>