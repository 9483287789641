import axios from 'axios';
import { format } from 'date-fns';

export default {
  namespaced: true,

  state: {
    folderDataArray: [],
    filesInFolderArray: [],
    currentFolderId: null,
  },

  getters: {
    PARENT_FOLDER_ARRAY(state) {
      return state.folderDataArray;
    },
    CURRENT_FOLDER_CONTENTS(state) {
      return state.currentFolderId === null ? state.folderDataArray : state.filesInFolderArray;
    },
    CURRENT_FOLDER_ID: state => state.currentFolderId,
  },

  mutations: {
    SET_PARENT_FOLDERS(state, payload) {
      state.folderDataArray = payload;
    },
    SET_FILES_IN_FOLDER(state, payload) {
      state.filesInFolderArray = payload;
    },
    ADD_FOLDER(state, folder) {
      state.folderDataArray.push(folder);
    },
    ADD_FILE(state, file) {
      state.filesInFolderArray.push(file);
    },
    SET_CURRENT_FOLDER_ID(state, folderId) {
      state.currentFolderId = folderId;
    },
    SET_CURRENT_FOLDER_ID(state, folderId) {
      state.currentFolderId = folderId;
    },
  },

  actions: {
    async FETCH_PARENT_FOLDERS({ commit, rootGetters }) {
      commit('SET_LOADING', true, { root: true });

      try {
        const token = rootGetters['auth/GET_TOKEN'];
        
        const response = await axios.get('parent-files', {
          headers: {
            Authorization: `Bearer ${token}` 
          }
        });

        const results = response.data.data.map(folder => ({
          id: folder.id,
          name: folder.name,
          date: formatDate(folder.date_created),
          isFolder: folder.is_folder,
          // Add any other properties you need
        }));

        commit('SET_PARENT_FOLDERS', results);
        commit('SET_CURRENT_FOLDER_ID', null);
        commit('SET_FILES_IN_FOLDER', []); // Clear the files in folder
        commit('SET_LOADING', false, { root: true });

        return { success: true };
      } catch (error) {
        console.error('Failed to fetch parent folders:', error);
        commit('SET_LOADING', false, { root: true });
        return { error: true, errorMsg: "Failed to fetch folders." };
      }
    },

    async FETCH_FOLDER_CONTENTS({ commit, rootGetters }, folderId) {
      commit('SET_LOADING', true, { root: true });

      try {
        const token = rootGetters['auth/GET_TOKEN'];
        const response = await axios.get(`folders/${folderId}/files/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const contents = response.data.data.map(item => ({
          id: item.id,
          name: item.name,
          date: formatDate(item.date_created),
          isFolder: item.is_folder,
          file: item.file,
          // Add any other properties you need
        }));

        commit('SET_FILES_IN_FOLDER', contents);
        commit('SET_CURRENT_FOLDER_ID', folderId);
        commit('SET_LOADING', false, { root: true });

        return { success: true };
      } catch (error) {
        console.error('Failed to fetch folder contents:', error);
        commit('SET_LOADING', false, { root: true });
        return { error: true, errorMsg: 'Failed to fetch folder contents' };
      }
    },

    async CREATE_FOLDER({ commit, rootGetters }, folderData) {
      try {
        const token = rootGetters['auth/GET_TOKEN'];
        const response = await axios.post('folders/', folderData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const newFolder = {
          id: response.data.id,
          name: response.data.name,
          date: formatDate(response.data.date_created),
          isFolder: true,
        };

        commit('ADD_FOLDER', newFolder);
        return { success: true, folder: newFolder };
      } catch (error) {
        console.error('Failed to create folder:', error);
        return { error: true, errorMsg: 'Failed to create folder' };
      }
    },

    async UPLOAD_FILE({ commit, rootGetters }, { file, folderId }) {
      try {
        const token = rootGetters['auth/GET_TOKEN'];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('folder', folderId);

        const response = await axios.post('files/', formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });

        const newFile = {
          id: response.data.id,
          name: response.data.name,
          date: formatDate(response.data.date_created),
          isFolder: false,
          file: response.data.file,
        };

        commit('ADD_FILE', newFile);
        return { success: true, file: newFile };
      } catch (error) {
        console.error('Failed to upload file:', error);
        return { error: true, errorMsg: 'Failed to upload file' };
      }
    },
  },
};

function formatDate(dateString) {
  if (!dateString) return '';
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    console.error('Invalid date:', dateString);
    return '';
  }
  return format(date, 'dd/MM/yyyy');
}