import { createApp, defineAsyncComponent } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/tailwind.css'

import { nextTick } from 'vue'

// element
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// axios
import axios from 'axios';
import VueAxios from 'vue-axios';

// calendar
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';

//apex charts
import VueApexCharts from "vue3-apexcharts";


const Spinner = defineAsyncComponent(() => import('@/components/UI/BaseLoading.vue'));

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { faEye, faEyeSlash, faUser } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons'


import { faXTwitter, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons'

import {faCircleNotch, faExclamationCircle, faBriefcase,faArrowRightFromBracket, faEnvelope, faCloudUploadAlt, faBell, faMagnifyingGlass, faFolder,faDownload, faSpinner, faCheck, faPlus, faFile, faTimes, faTrash, faSortDesc, faCirclePlus, faCaretDown,} from '@fortawesome/free-solid-svg-icons'

axios.defaults.baseURL = process.env.BASEURL

library.add(
  // regular
  faEye, faEyeSlash, faUser, fas,

  //brands
  faXTwitter, faInstagram, faFacebook,

  // solid
  faCircleNotch, faFile, faTimes, faCloudUploadAlt, faExclamationCircle, faBriefcase,faArrowRightFromBracket, faEnvelope, faBell, faMagnifyingGlass, faFolder, faDownload, faSpinner, faCheck, faPlus, faTrash, faSortDesc, faCirclePlus, faCaretDown
);

store.dispatch('auth/INIT_STORE')
store.dispatch('auth/ASSIGN_TOKEN').then(() => {
  const app = createApp(App);
  app.use(nextTick);
  app.use(store);
  app.use(router);
  app.use(ElementPlus);
  app.use(VueAxios, axios);
  app.use(VueApexCharts);

  app.component('fa-icon', FontAwesomeIcon);
  app.component('spinner', Spinner);

  app.component('VCalendar', Calendar)
  app.component('VDatePicker', DatePicker)

  app.mount('#app');
});