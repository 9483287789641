import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index';
import { adminRoutes } from './adminRoutes';
import { jwtDecode } from 'jwt-decode';

const routes = [
  
  //test
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "test" */ '../components/MODAL/AddMilestone.vue'),
    //component: () => import(/* webpackChunkName: "test" */ '../views/TestScreen.vue'),
  },

  // not found
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component:  () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue'),
    meta: {
      title: 'Not Found'
    }
  },

  // redirect
  {
    path: '/', 
    redirect: '/dashboard'
  },

  // sign-up
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () => import(/* webpackChunkName: "sign-up" */ '@/views/auth/CreateAccount.vue'),
    meta: {
      title: 'Sign Up'
    }
  },

  // login
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue'),
    meta: {
      title: 'Login'
    }
  },

  // reset-password
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "reset-password" */ '@/views/auth/ResetPassword.vue'),
    meta: {
      title: 'Reset Password'
    }
  },

  // dashboard
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true
    },
    
  },

  // projects
  {
    path: '/projects',
    name: 'proj',
    component: () => import(/* webpackChunkName: "projects" */ '../views/proj/Projects.vue'),
    meta: {
      title: 'Projects'
    },

    beforeEnter: (to, from, next) => {
      store.dispatch('project/FETCH_PROJECTS')
      .then(() => {
        next(); 
      });

      // try {
      //   store.dispatch('project/FETCH_PROJECTS');
        
      //   next();
      // } catch (error) {
      //   console.error('Failed to fetch data:', error);
      //   next(false);
      // }
    },

    children: [

      // client project
      {
        path: ':projId',
        name: 'client-proj',
        component: () => import(/* webpackChunkName: "clientproject" */ '../components/Projects/each-proj/EachProject.vue'),
        props: true,
        meta: {
          title: 'Client'
        },
        redirect: { name: 'summary' },

        beforeEnter(to, from, next) {
          Promise.all([
            store.dispatch('project/FETCH_PROJ_SUMMARY', to.params.projId),
            store.dispatch('project/FETCH_PROJ_TRANSAC', to.params.projId)
          ])
          .then(() => {
            next();
          })
          .catch(error => {
            console.error('Failed to fetch data:', error);
            next(false);
          });
        },

       
    
        children: [
          {
            path: 'summary',
            name: 'summary',
            component: () => import(/* webpackChunkName: "summary" */ '../views/proj/proj-view/Summary.vue')
          },
          {
            path: 'milestone',
            name: 'milestone',
            component: () => import(/* webpackChunkName: "milestone" */ '../views/proj/proj-view/Milestone.vue'),
            props: true,
          },
          {
            path: 'transaction',
            name: 'transaction',
            component: () => import(/* webpackChunkName: "transaction" */ '../views/proj/proj-view/Transation.vue'),
          },
          {
            path: 'documents',
            name: 'documents',
            component: () => import(/* webpackChunkName: "documents" */ '../views/proj/proj-view/ProjDocuments.vue'),
          },
          {
            path: 'reports',
            name: 'reports',
            component: () => import(/* webpackChunkName: "reports" */ '../views/proj/proj-view/Report.vue'),
          },
    
        ]
      },
    ]
  },

  // document
  {
    path: '/document',
    name: 'doc',
    component:  () => import(/* webpackChunkName: "documents" */ '../views/doc/Documents.vue'),
    meta: {
      title: 'Documents'
    },
    children: [
      {
        path: 'folder',
        name: 'folder',
        component: () => import(/* webpackChunkName: "subfolder" */ '../views/doc/SubFolders.vue'),
      }
    ]
  },

  //TAsks
  {
    path: '/tasks',
    name: 'tasks',
    component: () => import(/* webpackChunkName: "tasks" */ '../views/Tasks.vue'),
    meta: {
      title: 'Tasks'
    }
  },

  //Billings
  {
    path: '/billing',
    name: 'billing',
    component: () => import(/* webpackChunkName: "billing" */ '../views/billings/Billing.vue'),
    meta: {
      title: 'Billing'
    },
  },

  //Marketing
  {
    path: '/marketing',
    name: 'marketing',
    component: () => import(/* webpackChunkName: "marketing" */ '../views/marketing/Marketing.vue'),
    meta: {
      title: 'Marketing'
    },
  },

  //Reports
  {
    path: '/reports',
    name: 'reports',
    component: () => import(/* webpackChunkName: "reports" */ '../views/reports/Reports.vue'),
    props: true,
    meta: {
      title: 'Reports'
    },
    redirect: { name: 'overview'},

    children: [
    
      {
        path: '',
        name: 'report-month',
        component: () => import(/* webpackChunkName: "report-month" */ '../components/Report/MonthlyReport.vue'),
        props: true,
        children: [
          {
            path: ':id', name: 'Que', component: () => import(/* webpackChunkName: "FlowList" */ '../components/Report/FlowList.vue'),
          },
        ]
      },

      {
        path: '',
        name: 'overview',
        component: () => import(/* webpackChunkName: "overview" */ '../components/Report/Overview.vue'),
        props: true,
      }
    ]
  },

  //Settings
  {
    path: '/setting',
    name: 'setting',
    component: () => import(/* webpackChunkName: "setting" */ '@/views/settings/Settings.vue'),
    meta: {
      title: 'Settings',
      
    },
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...routes, ...adminRoutes],
});

router.beforeEach(async (to, from, next) => {
  document.title = `${to.meta.title} | ConnexPros`;

  store.commit('SET_LOADING', true);

  const token = await store.getters['auth/GET_TOKEN'];
  const isAuthenticated = store.getters['auth/IS_AUTH'];
  const isAdminRoute = to.matched.some(record => record.meta.isAdmin);


  console.log('Current route:', to.name);
  console.log('Is authenticated:', isAuthenticated);
  console.log('Is admin route:', to.meta.isAdmin);

  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        console.log('Token is expired');
        await store.dispatch('auth/LOGOUT');
        store.commit('SET_LOADING', false);
        return next({ name: 'login' });
      }
    } catch (error) {
      console.log('Error decoding token');
      await store.dispatch('auth/LOGOUT');
      store.commit('SET_LOADING', false);
      return next({ name: 'login' });
    }
  }

  // Allow access to login pages and sign-up
  if (to.name === 'login' || to.name === 'admin-login' || to.name === 'sign-up') {
    store.commit('SET_LOADING', false);
    return next();
  }

  // Redirect unauthenticated users
  if (!isAuthenticated && to.meta.requiresAuth) {
    console.log('Unauthenticated user, redirecting');
    store.commit('SET_LOADING', false);
    if (to.meta.isAdmin) {
      return next({ name: 'admin-login' });
    } else {
      return next({ name: 'login' });
    }
  }

  

  // Handle 'not-found' route
  if (to.name === 'not-found') {
    store.commit('auth/SET_NOT_FOUND', true);
  } else {
    store.commit('auth/SET_NOT_FOUND', false);
  }

  store.commit('SET_LOADING', false);
  next();
});

router.afterEach(() => {
  store.commit('SET_LOADING', false);
});

export default router
